import axios from 'axios'
import { AxiosResponse } from 'axios'
import config from '../../config/index'

const apiPath = {
  project: '/mpapi/project',
  getProject: '/mpapi/getproject',
  getOnlyProject: '/mpapi/onlyproject',
  problem: '/mpapi/projectprob',
  weekplan: '/mpapi/projectplan',
  rsweekplandesc: '/mpapi/getrsweekplandesc',
  projectTotal: '/mpapi/project/total',
  projectHours: '/mpapi/project/total/hours',
  projectHoursExcel: '/mpapi/project/total/hours/excel',
  projectActualHours: '/mpapi/actualworkhours',
  upCode: '/mpapi/updateProjectCode',
  productplan: '/mpapi/projectproductplan'
}

async function getProject(param: object): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.project, param)
  return result.data
}
async function getOnlyProject(param: object): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.getOnlyProject, param)
  return result.data
}
function getProjectExcel(param: string) {
  window.open(config.url + apiPath.project + param)
  // axios.get(config.url + apiPath.rsweekplanexcel + param)
  //   .then((response) => {
  //     cb(response);
  // });
}
function projectHoursExcel(param: string, cb) {
  window.open(config.url + apiPath.projectHoursExcel + param)
  // axios.get(config.url + apiPath.rsweekplanexcel + param)
  //   .then((response) => {
  //     cb(response);
  // });
}

async function addProject(param: object): Promise<AxiosResponse> {
  const result = await axios.post(config.url + apiPath.project, param)
  return result.data
}

async function updateProject(param: object): Promise<AxiosResponse> {
  const result = await axios.put(config.url + apiPath.project, param)
  return result.data
}

async function getRsWeekplanDesc(param: object): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.rsweekplandesc, param)
  return result.data
}

async function getProjectWeekplan(param: object): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.weekplan, param)
  return result.data
}
async function addProjectWeekplan(param: object): Promise<AxiosResponse> {
  const result = await axios.post(config.url + apiPath.weekplan, param)
  return result.data
}
async function updateProjectWeekplan(param: object): Promise<AxiosResponse> {
  const result = await axios.put(config.url + apiPath.weekplan, param)
  return result.data
}
async function deleteProjectWeekplan(param: object): Promise<AxiosResponse> {
  const result = await axios.delete(config.url + apiPath.weekplan, param)
  return result.data
}

async function getProjectProblem(param: object): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.problem, param)
  return result.data
}
async function updateProjectProblem(param: object): Promise<AxiosResponse> {
  const result = await axios.put(config.url + apiPath.problem, param)
  return result.data
}
async function deleteProjectProblem(param: object): Promise<AxiosResponse> {
  const result = await axios.delete(config.url + apiPath.problem, param)
  return result.data
}
async function addProjectProblem(param: object): Promise<AxiosResponse> {
  const result = await axios.post(config.url + apiPath.problem, param)
  return result.data
}

async function getProjectTotal(param: object): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.projectTotal, param)
  return result.data
}

async function getProjectHours(param: object): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.projectHours, param)
  return result.data
}

async function getProjectActualHours(param: object): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.projectActualHours, param)
  return result.data
}

async function updateProjectCode(param: object): Promise<AxiosResponse> {
  const result = await axios.post(config.url + apiPath.upCode, param)
  return result.data
}


async function getProjectProductplan(param: object): Promise<AxiosResponse> {
  const result = await axios.get(config.url + apiPath.productplan, param)
  return result.data
}
async function addProjectProductplan(param: object): Promise<AxiosResponse> {
  const result = await axios.post(config.url + apiPath.productplan, param)
  return result.data
}
async function updateProjectProductplan(param: object): Promise<AxiosResponse> {
  const result = await axios.put(config.url + apiPath.productplan, param)
  return result.data
}
async function deleteProjectProductplan(param: object): Promise<AxiosResponse> {
  const result = await axios.delete(config.url + apiPath.productplan, param)
  return result.data
}

export default {
  getProject,
  getOnlyProject,
  updateProject,
  getProjectWeekplan,
  getProjectProblem,
  addProjectWeekplan,
  deleteProjectWeekplan,
  updateProjectWeekplan,
  addProject,
  updateProjectProblem,
  deleteProjectProblem,
  addProjectProblem,
  getProjectExcel,
  getProjectTotal,
  getProjectHours,
  projectHoursExcel,
  updateProjectCode,
  getRsWeekplanDesc,
  getProjectActualHours,
  getProjectProductplan,
  addProjectProductplan,
  updateProjectProductplan,
  deleteProjectProductplan,
}
