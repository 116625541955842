
import { Component, Vue, Watch } from 'vue-facing-decorator'
import projectService from '../../../services/projectmanager/projectservice'
import milestoneservice from '../../../services/projectmanager/milestoneservice'
import attachfileservice from '../../../services/projectmanager/attachfileservice'
import moment from 'moment'
import * as _ from 'lodash'

@Component
export default class WeekShow extends Vue {

      dataparams = JSON.parse(localStorage.getItem('weekshowparams'))
      prePro = null
      nextPro = null
      monday = moment().isoWeekday(1).format('YYYY-MM-DD')
      premonday = moment().isoWeekday(1).subtract(7, 'day').format('YYYY-MM-DD')
      nextmonday = moment().isoWeekday(1).add(7, 'day').format('YYYY-MM-DD')
      lodash = _
      calcHeight = document.documentElement.clientHeight - 60
      curPro = this.dataparams.curProject
      proitems = [this.dataparams.curProject]
      milestoneitems = []
      projectPlans = []
      weekplanitems = []
      probitems = []
      proborriskoptions = [
        {
          value: '0',
          text: '问题',
        },
        {
          value: '1',
          text: '风险',
        },
      ]
      projectstatusclassoptions = [
        {
          value: '0',
          text: '提前',
        },
        {
          value: '1',
          text: '正常',
        },
        {
          value: '2',
          text: '预警',
        },
        {
          value: '3',
          text: '延期',
        },
        {
          value: '4',
          text: '暂停',
        },
      ]
      probtypeoptions = [
        {
          value: '0',
          text: '打开',
        },
        {
          value: '1',
          text: '预警',
        },
        {
          value: '2',
          text: '紧急',
        },
        {
          value: '3',
          text: '延期',
        },
        {
          value: '4',
          text: '关闭',
        },
      ]
      projecttypeoptions = [
        {
          value: '0',
          text: '降成本',
        },
        {
          value: '1',
          text: '新品',
        },
        {
          value: '2',
          text: '资源项目',
        },
        {
          value: '3',
          text: '二次开发',
        },
        {
          value: '4',
          text: '产品预研',
        },
      ]
      fileitems = []
    

  private created() {
    const curPro: any = this.dataparams.curProject
    const pros: any = this.dataparams.pros
    const filtered = _.filter(pros, { project_code: curPro.project_code })
    const index = _.indexOf(pros, filtered[0])
    this.prePro = pros[index - 1]
    this.nextPro = pros[index + 1]
    this.getDetailData()
  }

  private getDetailData() {
    this.getMilestones(this.curPro.project_code)
    this.getWeekPlans(this.curPro.project_code)
    this.getProblems(this.curPro.project_code)
    this.getAttachFile(this.curPro.project_code)
    this.getProductPlans(this.curPro.project_code)
  }

  private async getMilestones(project_code: string) {
    const param = {}
    param['is_show'] = JSON.stringify(['1'])
    param['project_code'] = project_code
    const result: any = await milestoneservice.getProjectMilestone({
      params: param,
    })
    // const result: any = await milestoneservice.getProjectMilestone({
    //     params: {
    //         project_code
    //     }
    // });
    if (result.success) {
      this.milestoneitems = result.rows
    }
  }

  // 获取产品计划列表
  public async getProductPlans(project_code) {
    const param = {
      project_code
    }
    const result: any = await projectService.getProjectProductplan({
      params: param
    })
    this.projectPlans = result.rows;

  }

  private async getAttachFile(project_code: string) {
    const result: any = await attachfileservice.getAttachFile({
      params: { project_code },
    })
    if (result.success) {
      this.fileitems = result.rows
    }
  }
  downloadAttachFile(url, name) {
    var self = this
    var xhr = new XMLHttpRequest()
    xhr.open('GET', url)
    xhr.responseType = 'blob'
    xhr.onload = function () {
      self.saveAs(xhr.response, name)
    }

    xhr.onerror = function () {
      console.error('could not download file')
    }

    xhr.send()
  }
  saveAs(data, name) {
    const blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=utf-8',
    })
    const downloadElement = document.createElement('a')
    const href = window.URL.createObjectURL(blob) //创建下载的链接
    downloadElement.href = href
    downloadElement.download = name //下载后文件名
    document.body.appendChild(downloadElement)
    downloadElement.click() //点击下载
    document.body.removeChild(downloadElement) //下载完成移除元素
    window.URL.revokeObjectURL(href)
  }

  private async getWeekPlans(project_code: string) {
    const result: any = await projectService.getProjectWeekplan({
      params: {
        project_code,
        startdate1: moment(this.monday)
          .subtract(7, 'days')
          .format('YYYY-MM-DD'),
        enddate1: moment(this.monday).subtract(1, 'days').format('YYYY-MM-DD'),
        startdate: this.monday,
        enddate: moment(this.monday).add(6, 'days').format('YYYY-MM-DD'),
      },
    })
    if (result.success) {
      if (!result.curweek) {
        result.curweek = {}
        result.curweek.current_week = result.lastweek
          ? result.lastweek.next_week
          : ''
      } else if (!result.curweek.current_week) {
        result.curweek.current_week = result.lastweek.next_week
      }
      this.weekplanitems = [result.curweek]
    }
  }

  private async getProblems(project_code: string) {
    const result: any = await projectService.getProjectProblem({
      params: {
        project_code,
        // startdate: this.monday,
        // enddate: moment(this.monday)
        //     .add(6, "days")
        //     .format("YYYY-MM-DD")
      },
    })
    if (result.success) {
      this.probitems = result.rows
    }
  }

  private goToPrevProject() {
    // alert('正在开发中');
    if (!this.prePro) {
      return
    }
    const index = _.indexOf(this.dataparams.pros, this.prePro)
    this.prePro = this.dataparams.pros[index - 1]
    this.curPro = this.dataparams.pros[index]
    this.proitems = [this.curPro]
    this.nextPro = this.dataparams.pros[index + 1]
    this.getDetailData()
  }

  private goToNextProject() {
    // alert('正在开发中');
    if (!this.nextPro) {
      return
    }
    const index = _.indexOf(this.dataparams.pros, this.nextPro)
    this.prePro = this.dataparams.pros[index - 1]
    this.curPro = this.dataparams.pros[index]
    this.proitems = [this.curPro]
    this.nextPro = this.dataparams.pros[index + 1]
    this.getDetailData()
  }

  private gotoPreWeek() {
    this.monday = moment(this.monday).subtract(7, 'days').format('YYYY-MM-DD')
    this.premonday = moment(this.premonday)
      .subtract(7, 'days')
      .format('YYYY-MM-DD')
    this.nextmonday = moment(this.nextmonday)
      .subtract(7, 'days')
      .format('YYYY-MM-DD')
    this.getDetailData()
  }

  private gotoNextWeek() {
    this.monday = moment(this.monday).add(7, 'days').format('YYYY-MM-DD')
    this.premonday = moment(this.premonday).add(7, 'days').format('YYYY-MM-DD')
    this.nextmonday = moment(this.nextmonday)
      .add(7, 'days')
      .format('YYYY-MM-DD')
    this.getDetailData()
  }

  private handleCellClass({ row, column, rowIndex, columnIndex }): string {
    if (columnIndex === 4 && row[column.property] === '1') {
      return 'yellow'
    }
    if (columnIndex === 4 && row[column.property] === '2') {
      return 'purple'
    }
    if (columnIndex === 4 && row[column.property] === '3') {
      return 'red'
    }
    return null
  }
  private handleMainTableCellClass({
    row,
    column,
    rowIndex,
    columnIndex,
  }): string {
    if (
      columnIndex === 5 &&
      (row[column.property] === '0' || row[column.property] === '1')
    ) {
      return 'blue'
    }
    if (columnIndex === 5 && row[column.property] === '2') {
      return 'yellow'
    }
    if (columnIndex === 5 && row[column.property] === '3') {
      return 'red'
    }
    if (columnIndex === 5 && row[column.property] === '4') {
      return 'gray'
    }
    return null
  }
}
